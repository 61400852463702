import { StyleSheet, View, Image,Text } from 'react-native';

export function Avis() {
    return(
<View style={styles.container}>
    <View style={styles.title}>
        <Text style={styles.textTitle}>
            Avis
        </Text>
    </View>
    <View style={styles.containerFlex}>
    <Image style={styles.img}
            source={require('./src/avis.PNG')}
          />
    </View>
</View>
    )
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    containerFlex: {
      flexDirection: 'row',
      justifyContent: 'center',
  
    },
    img: {
      width: 1000,
      height: 400,
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 100,
  
    },
    textTitle: {
      fontSize: 50,
    }
  });
  