import { Navbar } from './pages/navbar'
import { HomeScreen } from './pages/homescreen';
import { StyleSheet, View } from 'react-native';
import { Menu } from './pages/Menu';
import { Localisation } from './pages/localisation';
import { Avis } from './pages/avis';
import { Contact } from './pages/contact';
import { Reserver } from './pages/reserver';

export default function App() {
  return (
    <View style={styles.container}>
      <Navbar />
      <HomeScreen />
      <View style={styles.container2}>
        <Menu />
      </View>
      <View style={styles.container2}>
        <Localisation />
      </View>
      <View style={styles.container2}>
        <Avis />
      </View>
      <View style={styles.container2}>
        <Reserver />
      </View>
      <View style={styles.container2}>
        <Contact />
      </View>


    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  container2: {
    marginTop: 150,

  }
});

