import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';
import React from 'react';

export function Navbar() {
    return (

        <View style={styles.container}>
            <Text style={styles.textTitle}>Chez felix</Text>
            <View style={styles.rightbar}>
                <View style={styles.buttoncont}>
                    <TouchableOpacity style={styles.button} >
                        <Text>Carte</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.buttoncont}>
                    <TouchableOpacity style={styles.button}>
                        <Text>Localisation</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.buttoncont}>
                    <TouchableOpacity style={styles.button} >
                        <Text>Reserver</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.buttoncont}>
                    <TouchableOpacity style={styles.button} >
                        <Text>Contact</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>




    );
}

const styles = StyleSheet.create({
    container: {
        height: 80,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    textTitle: {
        fontSize: 25,
    },
    rightbar: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    buttoncont: {
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    button: {
        width: 85,
        height: 35,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#DDDDDD',
        borderRadius: 5,
    }
});
