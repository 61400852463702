import { useState } from 'react';
import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet, Text, FlatList } from 'react-native';
import { Card } from '@rneui/themed';


const styles = StyleSheet.create({

  //image logo
  imageLogo: {
    width: 90,
    height: 80,
  },
  //button inter
  buttonInter: {
    borderWidth: 1,
    borderRadius: 10,
  },

  // card
  displayCard: {
    flexDirection: 'row',
  },
  containerCard: {
    width: 1300,
    height: 600,
    flexDirection: 'row',
    justifyContent: 'center',

  },
  item: {
    padding: 10,
    fontSize: 15,
    height: 44,
  },
  contCard: {
    width: 400,
    marginLeft: 20,
  },
  // card image
  cardImage: {
    width: '80%',
    height: 250,
    marginLeft: 33,
    marginBottom: 15,
  },

  //button

  containerButton: {
    flexDirection: 'column',

  },

  button: {
    paddingBottom: 10,
  },

  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,

  },
  //title

  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 100,

  },
  textTitle: {
    fontSize: 50,
  }
});

const views = [
  //entree
  <View style={styles.displayCard}>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/entree.PNG')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>
    </View>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/entree1.jpg')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>

    </View>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/entree2.jpg')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>

    </View>
  </View>,
  // plat
  <View style={styles.displayCard}>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/plat.PNG')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>
    </View>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/plat1.PNG')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>
    </View>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/plat2.PNG')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>
    </View>
  </View>,
  //desert
  <View style={styles.displayCard}>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/dessert1.PNG')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>
    </View>
    <View style={styles.contCard} >
      <Card>

        <Card.Image
          style={styles.cardImage}
          source={require('./src/menu/desert2.PNG')}
        />
        <Card.Divider />
        <FlatList
          data={[
            { key: 'Carpaccio de bœuf avec des câpres, des feuilles de roquette et du parmesan' },
            { key: 'Gaspacho de concombre et de menthe' },
            { key: 'Crevettes grillées avec de l ail et du persil' },
            { key: 'Assiette de charcuterie italienne avec du jambon de Parme, du salami et des olives' },
            { key: 'Potage aux légumes avec croûtons de pain grillé et fromage râpé' },
          ]}
          renderItem={({ item }) => <Text style={styles.item}>{item.key}</Text>}
        />

      </Card>
    </View>

  </View>

];


export function Menu() {
  const [currentViewIndex, setCurrentViewIndex] = useState(0);

  const changeView = (newIndex) => {
    setCurrentViewIndex(newIndex);
  };

  const [isPressed, setIsPressed] = useState(false);


  return (
    <View>
      <View style={styles.title}>
        <Text style={styles.textTitle}>Carte</Text>
      </View>
      <View style={styles.container}>
        <View style={styles.containerButton}>
          <View style={styles.button}>
            <TouchableOpacity style={styles.buttonInter} onPress={() => {
              const newIndex = (0);
              changeView(newIndex);
              
            }}>
              <Image style={styles.imageLogo} source={require('./src/menu/entree-logo.png')} />
            </TouchableOpacity>
          </View>
          <View style={styles.button}>
            <TouchableOpacity style={styles.buttonInter} onPress={() => {
              const newIndex = (1);
              changeView(newIndex);
            }}>
              <Image style={styles.imageLogo} source={require('./src/menu/plat logo.PNG')} />
            </TouchableOpacity>
          </View>
          <View style={styles.button}>
            <TouchableOpacity style={styles.buttonInter} onPress={() => {
              const newIndex = (2);
              changeView(newIndex);
            }}> <Image style={styles.imageLogo} source={require('./src/menu/desert logo.jpeg')} />
            </TouchableOpacity>
          </View>
          <View style={styles.button}>
            <TouchableOpacity style={styles.buttonInter} onPress={() => {
              const newIndex = (3);
              changeView(newIndex);
            }}>
              <Image style={styles.imageLogo} source={require('./src/menu/logo drinks.PNG')} />
            </TouchableOpacity>

          </View>
        </View>
        <View style={styles.containerCard}>
          {views[currentViewIndex]}
        </View>
      </View>
    </View>

  )

};

